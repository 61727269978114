import React from 'react';
import { Row } from 'react-bootstrap';
import { customDateString } from '../Common/CommonFunctions';

function CalendarDay(props) {
    let calendarDateStyle = {
        backgroundColor: 'white',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
        width: '20px'
    }
    let calendarDayStyle = {
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        height: '100px',
        textAlign: 'center',
        width: '75px',
    }

    if (props.week === '0')
        calendarDayStyle.borderTop = '1px solid black';
    if (props.date?.getDay() === 0 || (props.week === '0' && props.dayIndex === 0))
        calendarDayStyle.borderLeft = '1px solid black';
    if (!props.date)
        calendarDayStyle.backgroundColor = '#292929';
    else if (!Object.hasOwn(props.games, customDateString(props.date)))
        calendarDayStyle.background = getBackgroundColor(props.inDateRange, 'darkgray');

    const dayGames = props.date && Object.hasOwn(props.games, customDateString(props.date))
        ? props.games[customDateString(props.date)] :
        [];
    return (
        <div
            onClick={() => props.updateDateRange(props.date)}
            style={calendarDayStyle}
        >
            {
                dayGames?.length == 0 && props.date &&
                <div
                    style={calendarDateStyle}
                >
                    {props.date.getDate()}
                </div>
            }

            {dayGames?.map((game, gameIndex) => {
                const gameStyle = {
                    background: getBackgroundColor(props.inDateRange, getColorHexByGmLI(game.gmLI)),
                    flexGrow: 1,
                };
                if (gameIndex > 0)
                    gameStyle.borderTop = '1px solid black';
                const teamGameNumbers = dayGames.map(dayGame => dayGame.TeamGameNumber).join('/');

                return <div key={gameIndex} style={gameStyle}>
                    {
                        props.date && gameIndex === 0 &&
                        <Row>
                            <div
                                style={calendarDateStyle}
                            >
                                {props.date.getDate()}
                            </div>
                            {
                                teamGameNumbers &&
                                <div style={{
                                    backgroundColor: 'white',
                                    margin: 'auto',
                                    width: props.date?.getDay() === 0 ? '53px' : '54px'
                                }}>
                                    #{teamGameNumbers}
                                </div>
                            }
                        </Row>
                    }
                    {
                        game.Inning &&
                        <>
                            <p>{convertOutsToIP(game.FractionalInnings)} IP {game.Saves ? 'S' : ''} {game.Holds ? 'H' : ''}</p>
                            <p>gmLI {Number(game.gmLI).toFixed(2)}</p>
                        </>
                    }
                    {
                        dayGames.length === 1 && game.Inning &&
                        <></>
                    }
                </div>
            })}
        </div>
    )
}

function convertOutsToIP(outs) {
    return Math.floor(outs / 3).toString() + '.' + (outs % 3).toString();
}

function getColorHexByGmLI(gmLI) {
    if (!gmLI)
        return '#ffffff';
    if (gmLI > 4)
        return '#FB8C00';
    else if (gmLI > 3.5)
        return '#FFA726';
    else if (gmLI > 3)
        return '#FFCC80';
    else if (gmLI > 2)
        return '#FFE0B2';
    else if (gmLI > 1)
        return '#FFF3E0';
    else if (gmLI > .8)
        return '#E1F5FE';
    else if (gmLI > .6)
        return '#B3E5FC';
    else if (gmLI > .4)
        return '#81D4FA';
    else if (gmLI > .2)
        return '#29B6F6';
    else
        return '#039BE5';
}

function getBackgroundColor(inDateRange, defaultColor = '#ffffff') {
    if (inDateRange) {
        return defaultColor;
    } else {
        return 'lightgrey';
        //return `repeating-linear-gradient(45deg, ${defaultColor}, ${defaultColor} 35px, #000000 40px, ${defaultColor} 44px)`;
    }
}

export default CalendarDay;