import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CalendarMonth from './CalendarMonth';
import DatePicker from '../Common/DatePicker';
import ExcelExporter from '../Common/ExcelExporter';
import TabulatorTable from '../Common/TabulatorTable';

function GameLog(props) {
    const [format, setFormat] = useState('calendar');
    


    // need to group by two to make sure a pdfChunk isn't too big to fit in pdf page
    return (
        <>
            <div className='pdfChunk'>
                <br />
                <Row>
                    <h3>Game Log</h3>
                    <b className='label' style={{ paddingLeft: '25px', paddingRight: '25px' }}>From: </b>
                    <DatePicker
                        maxDate={props.dateRange.endDate}
                        minDate={new Date(`1/1/${props.dateRange.startDate.getFullYear()}`)}
                        setDate={(date) => props.updateDateRange(date, 'start')}
                        startDate={props.dateRange.startDate}
                    />
                    <b className='label' style={{ padding: '0px 25px' }}>To: </b>
                    <DatePicker
                        endDate={props.dateRange.endDate}
                        maxDate={new Date(`12/31/${props.dateRange.startDate.getFullYear()}`)}
                        minDate={props.dateRange.startDate}
                        setDate={(date) => props.updateDateRange(date, 'end')}
                    />
                    <b className='label' style={{ paddingLeft: '25px' }}>Format: </b>
                    <Form>
                        <div key={'custom-inline-radio'}>
                            <Form.Check
                                defaultChecked
                                custom
                                inline
                                name='format'
                                label='Calendar View'
                                key={1}
                                type='radio'
                                id='custom-inline-radio-calendar'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setFormat('calendar')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='format'
                                label='Table View'
                                key={2}
                                type='radio'
                                id='custom-inline-radio-table'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setFormat('table')
                                    }
                                }}
                            />
                        </div>
                    </Form>
                </Row>
            </div>
            {
                format === 'calendar' &&
                [[2, 3], [4, 5], [6, 7], [8, 9]].map((monthGroup, monthGroupIndex) => {
                    return <Row className='pdfChunk' key={monthGroupIndex}>
                        {
                            monthGroup.map((month, monthIndex) => {
                                return <Col xs={12} xl={6}
                                    key={monthIndex}
                                >
                                    <CalendarMonth
                                        dateRange={props.dateRange}
                                        games={props.gameLogData}
                                        month={month}
                                        updateDateRange={props.updateDateRange}
                                        year={props.dateRange.startDate.getFullYear()}
                                    />
                                </Col>
                            })
                        }
                    </Row>
                })
            }
            {
                format === 'table' &&
                <div style={{
                    width: props.columns
                        ? (props.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                        : '100%'
                }}>
                    <TabulatorTable
                        cols={props.columns}
                        data={
                            Object.values(props.gameLogData)
                                .flatMap(row => row)
                                .filter(game => game.Inning > 0
                                    && game.GameDate >= props.dateRange.startDate
                                    && game.GameDate <= props.dateRange.endDate)
                        }
                        initSort={[{ column: 'GameDateString', dir: 'asc' }]}
                        selectable={false}
                    />
                </div>
            }
        </>
    )
}

export default GameLog;