import React, { useEffect } from 'react';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';

function TabulatorTable(props) {
    let tableEle = React.createRef();

    let cols = updateColumns(props.cols);

    let data = props.data;
    let dataTree = props.dataTreeElementColumn
        ? true
        : false;
    // If the default column exists use that
    // Else use the second column if available (to avoid issues with rank column)
    let dataTreeElementColumn = props.dataTreeElementColumn && cols.findIndex(column => column.field === props.dataTreeElementColumn) > -1
        ? props.dataTreeElementColumn
        : cols.length > 1
            ? cols[1].field
            : null;

    let pagination = null;
    let paginationSize = 0;
    let paginationCounter = null;
    let paginationSizeSelector = null;
    let paginationButtonCount = null;
    if (props.paginationSize > 0) {
        pagination = true;
        paginationSize = props.paginationSize;
        //paginationSizeSelector = [props.paginationSize, props.paginationSize * 2, props.paginationSize * 3]
        paginationCounter = props.paginationButtonCount < 4 ? null : 'rows';
        paginationButtonCount = props.paginationButtonCount ?? 5;
    }

    let initSort = null;
    if (props.initSort) {
        initSort = props.initSort;
    }
    let tabulator = null;

    function updateColumns(columns) {
        return columns.slice().map(column => {
            let newColumn = {};
            newColumn.cellClick = column.cellClick;
            newColumn.columns = column.columns;
            newColumn.cssClass = column.cssClass;
            newColumn.field = column.field;
            newColumn.formatter = column.formatter;
            newColumn.formatterParams = column.formatterParams;
            newColumn.headerSort = column.headerSort;
            newColumn.headerSortStartingDir = column.headerSortStartingDir;
            newColumn.hozAlign = column.hozAlign;
            newColumn.resizable = column.resizable;
            newColumn.sorter = column.sorter;
            newColumn.title = column.title;
            newColumn.titleFormatter = column.titleFormatter;
            newColumn.width = column.width;

            if (props.label && newColumn.title === 'Player')
                newColumn.title = props.label;

            return newColumn;
        });
    }

    useEffect(() => {
        tabulator = new Tabulator(tableEle, {
            data: data,
            reactiveData: false,
            columns: cols,
            columnDefaults: {
                headerHozAlign: "center",
            },
            layout: 'fitColumns',
            rowFormatter: function (row) {
                let rowData = row.getData();
                if (rowData.Player === 'Career Totals' && !rowData.scaleUp) {
                    row.getElement().style.borderTop = '1px solid black';
                }
                if ((rowData.TotalCategory && rowData.TotalCategory.includes('scaleUp'))
                        || rowData.scaleUp) {
                    row.getElement().className += ' scaleup-row';
                }
                if (rowData.IsOptionYear) {
                    row.getElement().className += ' option-year-row';
                }
                if (rowData.Player === 'Average') {
                    row.getElement().className += ' average-row';
                }
                if (rowData.AwardPlace === '1') {
                    row.getElement().className += ' first-place';
                }
                if (rowData.highlight) {
                    row.getElement().className += ' tabulator-selected';
                }
            },
            frozenRows: function (row) {
                return row.getData().Player === 'Average';
            },
            selectable: props.selectable ?? true,
            dataTree: dataTree,
            dataTreeElementColumn: dataTreeElementColumn,
            pagination: pagination,
            paginationButtonCount: paginationButtonCount,
            paginationSize: paginationSize,
            paginationCounter: paginationCounter,
            paginationSizeSelector: paginationSizeSelector,
            initialSort: initSort,
            movableColumns: props.movableColumns,
        });

        if (props.pdfExport) {
            let btn = document.getElementById('PdfExportBtn');
            btn.onclick = function () {
                tabulator.download("pdf", "queryTool.pdf", {
                    orientation: cols.reduce((acc, cur) => acc + cur.width, 0) > 560 ? 'landscape' : "portrait", //set page orientation to landscape if width>560
                    title: "Query Tool", //add title to report
                    autoTable: { //advanced table styling
                        theme: 'striped',
                        margin: { top: 40, left: 20, right: 20 },
                        columnStyles: cols.map(col => {
                            return { cellWidth: col.width }
                        })
                    },
                    documentProcessing: function (doc) {
                        //carry out an action on the doc object
                    }
                });
            }
        }
    },[props.data, props.cols]);

    return (
        <React.Fragment>
            {
                props.header &&
                <div className='table-header' style={{ width: `${props.header.length * 10}px` }}>{props.header}</div>
            }
            <div ref={el => (tableEle = el)} />
        </React.Fragment>
    )
}

export default TabulatorTable;