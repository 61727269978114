const statFunctions = (function () {
    function aggregateData(dataObjs, shouldAggregate, statNames) {
        let aggregatedStats = dataObjs
            .reduce(
                getStatObjReducer(
                    shouldAggregate,
                    statNames
                ),
                getInitStatObj(statNames)
            );

        aggregatedStats = generateNonCalculableStats(statNames, aggregatedStats, dataObjs[0] || {});
        aggregatedStats = generateNonCountingStats(statNames, aggregatedStats);

        return aggregatedStats;
    }

    function calculatePitchingBatAvg(statObj) {
        return statObj.pH / statObj.AB;
    }

    function calculateERA(statObj) {
        // Fractional Innings = Outs so divide by 27 instead of 9 (3 outs per inning)
        return (statObj.ER) / (statObj.FractionalInnings / 27);
    }

    function calculateFIP(statObj) {
        return (
            (13 * statObj.pHR
                + 3 * (statObj.pWalks + statObj.pHBP)
                - 2 * statObj.pStrikeouts)
            / (statObj.FractionalInnings / 3)
        ) + statObj.FIPConstant;
    }

    function calculateInnPitched(statObj) {
        return Math.floor(statObj.FractionalInnings / 3).toString()
            + '.' + ((statObj.FractionalInnings % 3) * 3).toString();
    }

    function calculateLateAndClose(statObj) {
        return statObj.LC / statObj.RAPP * 100;
    }

    function calculatePitchingOBP(statObj) {
        return (statObj.pH + statObj.pWalks + statObj.pHBP)
            / (statObj.AB + statObj.pWalks + statObj.SF + statObj.pHBP);
    }

    function calculatePitchingOPS(statObj) {
        return calculatePitchingOBP(statObj) + calculatePitchingSlug(statObj);
    }

    function calculatePitchingSlug(statObj) {
        return statObj.TB / statObj.AB;
    }

    function calculateWeightedStat(weightedStatValues, weight) {
        return weightedStatValues.reduce((acc, cur) => acc += (cur.value * cur.weight), 0) / weight;
    }

    function calculateWhip(statObj) {
        return (statObj.pWalks + statObj.pH) / (statObj.FractionalInnings / 3);
    }

    function generateNonCalculableStats(stats, aggregatedData, statObj) {
        let AggData = Object.assign({}, aggregatedData);

        stats.forEach(stat => {
            if (isNotCalculable(stat)) {
                AggData[stat] = statObj[stat];
            }
        });

        return AggData;
    }

    function generateNonCountingStats(stats, aggregatedData) {
        let AggData = Object.assign({}, aggregatedData);

        stats.forEach(stat => {
            if (isWeightedStat(stat)) {
                AggData[stat] = calculateWeightedStat(aggregatedData[stat + 'Weights'], AggData[weights[stat]]);
            } else if (isRateStat(stat)) {
                AggData[stat] = formulas[stat](aggregatedData);
            }
            
        });

        return AggData;
    }

    function getInitStatObj(columns) {
        return columns
            .reduce((acc, cur) => {
                if (isNotCalculable(cur)) {
                    acc[cur] = 'N/A';
                    return acc;
                }

                if (isWeightedStat(cur))
                    acc[cur + 'Weights'] = [];

                acc[cur] = 0;
                return acc;
            }, { counter: 0 })
    }

    function getStatObjReducer(shouldAggregate, stats) {
        function statObjReducer(aggregates, game) {
            if (!shouldAggregate(game))
                return aggregates;

            let aggregatedData = Object.assign({}, aggregates);

            stats.forEach(stat => {
                if (!Object.hasOwn(game, stat) || isNotCalculable(stat))
                    return;

                if (isWeightedStat(stat) && isRateStat(stat)) {
                    aggregatedData[stat + 'Weights'].push({
                        value: calculateFIP(game),
                        weight: game[weights[stat]],
                    });
                } else if (isWeightedStat(stat)) {
                    aggregatedData[stat + 'Weights'].push({
                        value: game[stat],
                        weight: game[weights[stat]],
                    });
                }
                else if (isRateStat(stat)) {
                    aggregatedData[stat] = null;
                }
                else {
                    aggregatedData[stat] += game[stat]
                }
            })
            aggregatedData.counter++;

            return aggregatedData;
        }

        return statObjReducer;
    }

    function isNotCalculable(statName) {
        return ['aWAR', 'FIPConstant'].includes(statName);
    }

    function isRateStat(statName) {
        return ['ERA', 'FIP', 'InnPitched', 'LCPercent', 'pBatAvg', 'pOPS', 'WHIP'].includes(statName);
    }

    function isWeightedStat(statName) {
        return ['exLI', 'gmLI', 'pLI'].includes(statName);
    }

    const formulas = {
        ERA: calculateERA,
        FIP: calculateFIP,
        InnPitched: calculateInnPitched,
        LCPercent: calculateLateAndClose,
        pBatAvg: calculatePitchingBatAvg,
        pOPS: calculatePitchingOPS,
        WHIP: calculateWhip,
    }

    const weights = {
        exLI: 'RAPP',
        FIP: 'FractionalInnings',
        gmLI: 'RAPP',
        pLI: 'pLIc',
    }

    return {
        aggregateData,
        generateNonCountingStats,
        getInitStatObj,
        getStatObjReducer,
        isRateStat,
    }
}());

export { statFunctions };