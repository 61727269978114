import React, { useEffect, useState } from 'react';
import { Row, Nav, Navbar, Button } from 'react-bootstrap';
import { GetPlayers, GetReportSplits } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ColumnSelector from '../Common/ColumnSelector';
import ExcelExporter from '../Common/ExcelExporter';
import MultiSelect from '../Common/MultiSelect';
import PDFExporter from '../Common/PDFExporter';
import PlayerSearch from '../Common/PlayerSearch';
import TabulatorTable from '../Common/TabulatorTable';
import TimeFilter from '../Common/TimeFilter';
import { Splits } from '../Common/Constants';

function SplitsReport() {
    const [allPlayers, setAllPlayers] = useState([]);
    const [category, setCategory] = useState(null);
    const [columns, setColumns] = useState(null);
    const [data, setData] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [groupId, setGroupId] = useState(10);
    const [player, setPlayer] = useState(null);
    const [splits, setSplits] = useState(null);
    const [timeFilter, setTimeFilter] = useState({
        season: 2023,
        mode: 'single',
    });

    useEffect(() => {
        (async function Initialize() {
            let asyncTasks = [
                GetPlayers(),
                columnManager.getAllColumns(),
                columnManager.getColumnsByGroup(groupId > 0 ? groupId : 10),
                columnManager.getAllGroupsBySection('Leaderboards'),
                columnManager.getAllGroupsBySection('SplitsReport'),
            ]

            let asyncResponses = await Promise.all(asyncTasks);
            let allPlayersFromAPI = asyncResponses[0];
            let allColumns = asyncResponses[1].filter(column => column.formInputs.granularity === 1);
            let selectedColumns = asyncResponses[2].filter(column => column.formInputs.granularity === 1);
            let leaderboardGroups = asyncResponses[3].map(group => {
                let columnIds = [];
                group.columns = group.columns.filter(column => {
                    if (column.formInputs.granularity === 1)
                        columnIds.push(column.id);
                    return column.formInputs.granularity === 1;
                });
                group.colIds = columnIds;
                return group;
            })
            let allSplits = asyncResponses[4].find(group => group.label === 'Splits').columns
            setSplits({
                selectedSplitIds: allSplits.map(split => split.id).join(','),
                splits: allSplits,
            });

            setColumns({
                columns: allColumns,
                selectedCols: selectedColumns,
                groups: leaderboardGroups,
            });

            setAllPlayers(allPlayersFromAPI);
        })()
    }, []);

    useEffect(() => {
        if (category) {
            let newSplits = Object.assign({}, splits);
            newSplits.selectedSplitIds = getSplitsListBySelectedCategory().map(split => split.id).join(',');
            setSplits(newSplits);
        }
    }, [category])

    useEffect(() => {
        if (player)
            getData();
    }, [columns])

    useEffect(() => {
        if (groupId < 1 || !columns || columns?.groups?.length < 1)
            return;

        let tempCols = columns.groups.find(group => group.id === groupId).columns.slice();
        const tempColData = Object.assign({}, columns);
        tempColData.selectedCols = tempCols;
        setColumns(tempColData);
    }, [groupId])

    useEffect(() => {
        if (!player)
            return;

        if (player.posFlag === 'B') {
            setCategory('batting');
            setGroupId(10);
        }
        else {
            setCategory('pitching');
            setGroupId(15);
        }

        setTimeFilter({
            endDate: `${player.maxSeason}-12-31`,
            endSeason: player.maxSeason,
            mode: 'single',
            season: player.maxSeason,
            startDate: `${player.maxSeason}-01-01`,
            startSeason: player.maxSeason,
        });
    }, [player])

    async function getData() {
        setData({ loading: true, rows: null })
        updateNotes();
        let selectedSplitIds = splits.selectedSplitIds?.split(',').map(splitId => Number(splitId));
        let response = await GetReportSplits(
            player.id,
            timeFilter,
            columns.selectedCols,
            splits.splits.filter(split => selectedSplitIds.includes(split.id)),
        );

        let rows = response.splitGroups
            .filter(splitGroup => splitGroup != null)
            .map(splitGroup => {
                let splitRows = Object.values(splitGroup)
                return {
                    Group: splitRows[0].Group,
                    _children: splitRows,
                }
            });
        
        setData({ exceptionMsgs: response.exceptionMsgs, loading: false, rows: rows });
    }

    function updateNotes() {
        let notes = {
            formNotes: [],
        };

        switch (timeFilter.mode) {
            case 'single':
                notes.formNotes.push(`Season: ${timeFilter.season}`);
                break;
            case 'cumulative':
                notes.formNotes.push(`Season From: ${timeFilter.startSeason} To: ${timeFilter.endSeason}`);
                break;
            case 'date':
                notes.formNotes.push(`Date From: ${timeFilter.startDate} To: ${timeFilter.endDate}`);
                break;
            default:

                break;
        }

        setExcelNotes(notes);
    }

    function getSplitsListBySelectedCategory() {
        if(category === 'pitching') 
            return splits.splits.filter((s) => ![Splits.PitchingLeague, Splits.PitchingTeam, Splits.PitcherHanded].includes(s.id));

        if(category === 'batting')
            return splits.splits.filter((s) => ![Splits.BattingLeague, Splits.BattingTeam, Splits.DaysOfRest, Splits.TimesThruOrder].includes(s.id));

        return splits.splits;
    }

    function renderSplitMultiselect() {
        const options = getSplitsListBySelectedCategory();
        return (
            <MultiSelect
                list={options.map(split => {
                    return {
                        label: split.title,
                        value: split.id.toString(),
                    }
                })}
                label={'value'}
                selectedItems={splits.selectedSplitIds?.split(',') || []}
                setSelectedItems={(selectedValues) => {
                    let splitObj = Object.assign({}, splits);
                    splitObj.selectedSplitIds = selectedValues.length > 0
                        ? selectedValues.join(',')
                        : null;
                    setSplits(splitObj);
                }}
            />            
        )
    }

    return (
        <>
            <div className='body splits-report pdfSection'>
                <div className='pdfChunk'>
                    <Row>
                        <h2>Splits {player ? `- ${player.name}` : ''}</h2>
                        <div style={{ margin: '0px 0px 0px auto' }}>
                            <PlayerSearch
                                key={0}
                                players={allPlayers}
                                label={'Player Search'}
                                onSelect={(playerId, platformSeason, posFlag) => {
                                    let selectedPlayer = allPlayers.find(player => player.id === playerId);
                                    setPlayer(selectedPlayer)
                                }}
                            />
                        </div>
                    </Row>
                    {
                        player && player.maxSeason > 2001 &&
                        <>
                            <TimeFilter
                                disallowScaleUp={true}
                                filter={timeFilter}
                                includeMonthSelector={false}
                                includePrePostAllStarSelector={false}
                                maxSeason={player.maxSeason > 2001 ? player.maxSeason : 2002}
                                minSeason={player.minSeason > 2001 ? player.minSeason : 2002}
                                modes={['single', 'cumulative', 'date']}
                                setFilter={setTimeFilter}
                            />
                            <br />
                            { renderSplitMultiselect() }
                            <Row>
                                <Button variant='success'
                                    onClick={getData}
                                >
                                    Submit
                                </Button>
                                <div style={{ margin: '0px 0px 0px auto' }}>
                                    {
                                        data?.rows &&
                                        <>
                                            <ExcelExporter
                                                columns={[
                                                    {
                                                        field: 'Group',
                                                        title: 'Group',
                                                        width: 175,
                                                    },
                                                    {
                                                        field: 'Label',
                                                        title: 'Label',
                                                        width: 175,
                                                    },
                                                ].concat(columns.selectedCols)}
                                                data={data.rows.flatMap(row => row._children)}
                                                notes={excelNotes}
                                                reportName={'ArbitrationReport'}
                                            />
                                            <PDFExporter fileName='ArbitrationReport' />
                                        </>
                                    }
                                </div>
                            </Row>
                        </>
                    }
                    {
                        player && player.maxSeason <= 2001 &&
                        <p>We do not have playing stats for this player</p>
                    }
                </div>
                {
                    data?.loading &&
                    <div>
                        <br />
                        <br />
                        <i className='fa fa-spinner fa-spin loading-icon'></i>
                        <br />
                    </div>
                }
                {
                    columns && data?.rows?.length > 0 &&
                    <>
                        <div className='pdfChunk'>
                            {
                                category === 'batting' &&
                                <Navbar bg='light' variant='light' className='leaderboardsGroupNav'>
                                    <Nav>
                                        <Nav.Link className={groupId === 10 ? 'selectedTab' : null} onClick={() => setGroupId(10)}>Overview</Nav.Link>
                                        <Nav.Link className={groupId === 11 ? 'selectedTab' : null} onClick={() => setGroupId(11)}>Standard</Nav.Link>
                                        <Nav.Link className={groupId === 12 ? 'selectedTab' : null} onClick={() => setGroupId(12)}>Advanced</Nav.Link>
                                    </Nav>
                                </Navbar>
                            }
                            {
                                category === 'pitching' &&
                                <Navbar bg='light' variant='light' className='leaderboardsGroupNav'>
                                    <Nav>
                                        <Nav.Link className={groupId === 15 ? 'selectedTab' : null} onClick={() => setGroupId(15)}>Overview</Nav.Link>
                                        <Nav.Link className={groupId === 16 ? 'selectedTab' : null} onClick={() => setGroupId(16)}>Standard</Nav.Link>
                                        <Nav.Link className={groupId === 17 ? 'selectedTab' : null} onClick={() => setGroupId(17)}>Run Prevention</Nav.Link>
                                        <Nav.Link className={groupId === 18 ? 'selectedTab' : null} onClick={() => setGroupId(18)}>Usage</Nav.Link>
                                        <Nav.Link className={groupId === 19 ? 'selectedTab' : null} onClick={() => setGroupId(19)}>Batted Ball</Nav.Link>
                                    </Nav>
                                </Navbar>
                            }
                            <br />
                        </div>
                        <div
                            className='pdfChunk tableChunk'
                            style={{
                                width: (columns.selectedCols?.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 350)) + 4
                            }}
                        >
                            <TabulatorTable
                                cols={[
                                    {
                                        field: 'Group',
                                        title: 'Group',
                                        width: 175,
                                    },
                                    {
                                        field: 'Label',
                                        title: 'Label',
                                        width: 175, 
                                    },
                                ].concat(columns.selectedCols)}
                                data={data.rows}
                                dataTreeElementColumn='Group'
                            />
                        </div>
                    </>
                }
                {
                    data?.exceptionMsgs &&
                    <React.Fragment>
                            {
                                data.exceptionMsgs.map(msg => {
                                    return <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>
                                        {msg}
                                    </p>
                                })
                            }
                        <br />
                        <br />
                    </React.Fragment>
                }
            </div>
            <br />
            {
                columns && player && player.maxSeason > 2001 &&
                <>
                    <ColumnSelector
                        columns={columns.columns}
                        selectedColumns={columns.selectedCols}
                        groups={columns.groups}
                        setColumns={(selectedCols) => {
                            const tempColData = Object.assign({}, columns);
                            tempColData.selectedCols = selectedCols;
                            setColumns(tempColData);
                        }}
                    />
                    <br />
                </>
            }
        </>
    )
}

export default SplitsReport;