import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { GetPlayers, GetReportArbitration } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import StatFilter, { addFilterAsParameter, convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';

function ArbitrationReport() {
    let curSeason = new Date().getFullYear();

    const [allPlayers, setAllPlayers] = useState([]);
    const [columns, setColumns] = useState(null);
    const [data, setData] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [filters, setFilters] = useState([]);
    const [endSeason, setEndSeason] = useState(curSeason);
    const [startSeason, setStartSeason] = useState(curSeason);

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            let colGroups = await columnManager.getAllGroupsBySection('ArbitrationReport');
            let arbFilters = [];

            colGroups.find(group => group.label === 'ArbitrationFilters').columns
                .forEach((column, filterIndex) => {
                    let filter = Object.assign({}, createFilter(filterIndex, column));
                    arbFilters.push(filter);
                });
            let arbColumns = colGroups.find(group => group.label === 'ArbitrationReport').columns;

            setAllPlayers(allPlayersFromAPI);
            setColumns(arbColumns);
            setFilters(arbFilters);
        })()
    }, []);

    async function getData() {
        setData({ loading: true, rows: null })
        let seasons = Array.from(
            new Array(endSeason - startSeason + 1),
            (season, seasonIndex) => seasonIndex + Number(startSeason)
        );
        let filterObj = filters.reduce(addFilterAsParameter, { seasons: seasons });
        let res = await GetReportArbitration(filterObj);
        updateNotes(filters);
        setData({ loading: false, rows: res });
    }

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function updateNotes(filters) {
        let notes = {
            formNotes: [],
        };
        notes.formNotes.push(`Filing Season From: ${startSeason} To: ${endSeason}`);

        if (filters) {
            filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    let seasonOptions = [];
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };
    return (
        <div className='body arbitration-report pdfSection'>
            <div className='pdfChunk'>
                <h2>Arbitration Viewer</h2>
                <Row>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season From: 
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={startSeason}
                        onChange={(event) => {
                            setStartSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season To:
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={endSeason}
                        onChange={(event) => {
                            setEndSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                </Row>
                <h5>Who's on this report</h5>
                <div className='advanced-filter'>
                    {
                        filters.map((filter, filterIndex) =>
                            <StatFilter
                                filter={filter}
                                key={filterIndex}
                                people={allPlayers}
                                setFilter={setFilter}
                            />
                        )
                    }
                </div>
                <br />
                <Row className='pdfIgnore'>
                    <Button variant='success'
                        onClick={getData}
                    >
                        Submit
                    </Button>
                    {
                        data?.rows &&
                        <>
                            <div style={{ margin: '0px 0px 0px auto' }}>
                                <ExcelExporter
                                    columns={columns}
                                    data={data.rows}
                                    notes={excelNotes}
                                    reportName={'ArbitrationReport'}
                                />
                                <PDFExporter fileName='ArbitrationReport' />
                            </div>
                        </>
                    }
                </Row>
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data?.rows && columns &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={columns}
                        data={data.rows}
                        initSort={[{
                            column: columns?.find(col => col.title === 'Player').field,
                            dir: 'asc'
                        }]}
                    />
                    <br />
                </div>
            }
        </div>
    )
}

export default ArbitrationReport;