let Positions = [
    { abbreviation: 'All', num: 0, posType: 'All' },
    { abbreviation: 'P', num: 1, posType: 'Batter' },
    { abbreviation: 'C', num: 2, posType: 'Batter' },
    { abbreviation: '1B', num: 3, posType: 'Batter' },
    { abbreviation: '2B', num: 4, posType: 'Batter' },
    { abbreviation: '3B', num: 5, posType: 'Batter' },
    { abbreviation: 'SS', num: 6, posType: 'Batter' },
    { abbreviation: 'LF', num: 7, posType: 'Batter' },
    { abbreviation: 'CF', num: 8, posType: 'Batter' },
    { abbreviation: 'RF', num: 9, posType: 'Batter' },
    { abbreviation: 'OF', num: 10, posType: 'Batter' },
    { abbreviation: 'DH', num: 11, posType: 'Batter' },
    { abbreviation: 'PH', num: 12, posType: 'Batter' },
    { abbreviation: 'PR', num: 13, posType: 'Batter' },
    { abbreviation: 'SP', num: 14, posType: 'Pitcher' },
    { abbreviation: 'RP', num: 15, posType: 'Pitcher' },
    { abbreviation: 'UTIL', num: 16, posType: 'Batter' },
]

/**
* returns an array containing all indices of a string(searchString) in another string(stringToSearch).
* @param {any} searchString
* @param {any} stringToSearch
*/
export function getIndicesOfString(searchString, stringToSearch) {
    let curIndex = -1;
    let indices = [];
    do {
        curIndex = stringToSearch.indexOf(searchString, curIndex + 1);
        if (!!~curIndex) {
            indices.push(curIndex);
        }
    } while (!!~curIndex)
    return indices;
}

export function GetPositionByAbbreviation(abbreviation) {
    return Positions.find(pos => pos.abbreviation === abbreviation);
}

export function GetPositionByPositionNumber(positionNumber) {
    return Positions.find(pos => pos.num === positionNumber);
}

export function GetPositionsByPosType(posType) {
    return Positions.filter(pos => pos.posType === posType || pos.posType === 'All');
}

export function GetPosNumFromAbbreviation(x) {
    return Positions.find((pos) => {
        return pos.abbreviation === x;
    }).num;
    //switch (x) {
    //    case 'All':
    //        return 0;
    //    case 'P':
    //        return 1;
    //    case 'C':
    //        return 2;
    //    case '1B':
    //        return 3;
    //    case '2B':
    //        return 4;
    //    case '3B':
    //        return 5;
    //    case 'SS':
    //        return 6;
    //    case 'LF':
    //        return 7;
    //    case 'CF':
    //        return 8;
    //    case 'RF':
    //        return 9;
    //    case 'DH':
    //        return 11;
    //    case 'PH':
    //        return 12;
    //    case 'PR':
    //        return 13;
    //}
}

export function GetPosAbbreviationFromNum(x) {
    return Positions.find((pos) => {
        return pos.num === x;
    }).abbreviation;
}

export function customDateString(date) {
    let year = date.toLocaleDateString('en-US', { year: 'numeric' });
    let month = date.toLocaleDateString('en-US', { month: 'numeric' });
    let day = date.toLocaleDateString('en-US', { day: 'numeric' });
    return `${String(year).padStart(4, '0')}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
}

export function getValueLabelFromFormInputs(formInputs, value) {
    let valueLabelArr = [];
    if (formInputs.selectOptions?.length > 0 && value?.length > 0) {
        let selectOptionsObj = {};
        formInputs.selectOptions.forEach(selectOption => {
            selectOptionsObj[selectOption.value] = selectOption.label;
        })
        value.split(',').forEach(tempFilterVal => {
            if (selectOptionsObj[tempFilterVal]) {
                valueLabelArr.push(selectOptionsObj[tempFilterVal]);
            }
        })
    }

    return valueLabelArr.length > 0
        ? valueLabelArr.join(',')
        : value;
}

export function toCurrency(value) {
    if (value)
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else
        return '';
}