import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Button, Form, Badge, Row, Col } from 'react-bootstrap';
import LeaderBoardForm from './LeaderboardForm';
import { GetLeaderboardsData, GetLeaderboardsPitchers, GetLeaderboardsBatters, GetLeaderboardsFielders, GetQualifierThresholds } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ExcelExporter from '../Common/ExcelExporter';
import ColumnSelector from '../Common/ColumnSelector';
import TabulatorTable from '../Common/TabulatorTable';

function Leaderboards() {
    const [data, setData] = useState([]);
    const [groupId, setGroupId] = useState(10);
    const [initSort, setInitSort] = useState([{ column: 'OPS', dir: 'desc' }]);
    const [colData, setColData] = useState({
        columns: [],
        extraColumns: [],
        selectedCols: [],
        groups: [],
    });
    const [formOptions, setFormOptions] = useState({ tabName: 'players' });
    const [pitchTypes, setPitchTypes] = useState({
        'fourseam': false,
        'sinker': false,
        'slider': false,
        'cutter': false,
        'curve': false,
        'change': false,
    });
    const [loading, setLoading] = useState(false);
    const [qualifierThresholds, setQualifierThresholds] = useState([]);
    const componentIsMounted = useRef(true);

    useEffect(() => {
        (async function getColumns() {
            let allQualifierThresholds = await GetQualifierThresholds()
            setQualifierThresholds(allQualifierThresholds);
            let allColumns = await columnManager.getAllColumns();
            let rankColumn = await columnManager.getSingleColumnById(10000);
            let leagueColumn = await columnManager.getSingleColumnById(499);
            let fourSeamColumns = await columnManager.getColumnsById([327, 328, 329, 330, 331, 332, 333, 10334]);
            let sinkerColumns = await columnManager.getColumnsById([335, 336, 337, 338, 339, 340, 341, 10342]);
            let sliderColumns = await columnManager.getColumnsById([343, 344, 345, 346, 347, 348, 349, 10350]);
            let cutterColumns = await columnManager.getColumnsById([351, 352, 353, 354, 355, 356, 357, 10358]);
            let curveColumns = await columnManager.getColumnsById([359, 360, 361, 362, 363, 364, 365, 10366]);
            let changeColumns = await columnManager.getColumnsById([367, 368, 369, 370, 371, 372, 373, 10374]);
            let curExtraColumns = [];
            curExtraColumns.push({
                name: 'Rank',
                columns: [
                    rankColumn,
                ],
            });
            curExtraColumns.push({
                name: 'League',
                columns: [
                    leagueColumn,
                ],
            });
            curExtraColumns.push(
                {
                    name: 'fourseam',
                    columns: fourSeamColumns,
                },
                {
                    name: 'sinker',
                    columns: sinkerColumns,
                },
                {
                    name: 'slider',
                    columns: sliderColumns,
                },
                {
                    name: 'cutter',
                    columns: cutterColumns,
                },
                {
                    name: 'curve',
                    columns: curveColumns,
                },
                {
                    name: 'change',
                    columns: changeColumns,
                },
            );
            let selectedColumns = await columnManager.getColumnsByGroup(groupId > 0 ? groupId : 10);
            let leaderboardGroups = await columnManager.getAllGroupsBySection('Leaderboards');
            setColData({
                columns: allColumns,
                extraColumns: curExtraColumns,
                selectedCols: addRequiredCols(selectedColumns, curExtraColumns),
                groups: leaderboardGroups,
            });
        })();

        return () => {
            componentIsMounted.current = false;
        }
    }, [])

    useEffect(() => {
        if (groupId > 0) {
            refreshData(() => {
                if (colData.groups.length < 1) {
                    return;
                }
                switch (groupId) {
                    case 10:
                        setInitSort([{ column: 'OPS', dir: 'desc' }]);
                        break;
                    case 11:
                        setInitSort([{ column: 'HR', dir: 'desc' }]);
                        break;
                    case 12:
                        setInitSort([{ column: 'fWAR', dir: 'desc' }]);
                        break;
                    case 13:
                        setInitSort([{ column: 'AvgEV', dir: 'desc' }]);
                        break;
                    case 14:
                        setInitSort([{ column: 'DRS', dir: 'desc' }]);
                        break;
                    case 15:
                    case 17:
                        setInitSort([{ column: 'ERA', dir: 'asc' }]);
                        break;
                    case 16:
                        setInitSort([{ column: 'pStrikeouts', dir: 'desc' }]);
                        break;
                    case 18:
                        setInitSort([{ column: 'gmLI', dir: 'desc' }]);
                        break;
                    case 19:
                        setInitSort([{ column: 'pStrikeoutPercentage', dir: 'desc' }]);
                        break;
                    case 20:
                        setInitSort([{ column: 'pAvgEV', dir: 'desc' }]);
                        break;
                    case 21:
                        setInitSort([{ column: 'IP', dir: 'desc' }]);
                        break;
                    case 22:
                        setInitSort([{ column: 'YSNext', dir: 'desc' }]);
                        break;
                }
                let tempCols = colData.groups.find(group => group.id === groupId).columns.slice();
                const tempColData = Object.create(colData);
                tempColData.selectedCols = addRequiredCols(tempCols);
                setColData(tempColData);
            })
        }
    }, [groupId])

    useEffect(() => {
        if (colData.selectedCols.length > 0) {
            getData();
        }
    }, [colData.selectedCols])

    useEffect(() => {
        if (colData.groups.length < 1) {
            return;
        }
        let tempCols = colData.groups.find(group => group.id === groupId).columns.slice();
        const tempColData = Object.create(colData);
        tempColData.selectedCols = addRequiredCols(tempCols);
        setColData(tempColData);
    }, [formOptions.tabName])

    function pitchArsenalChange(event) {
        let curPitchTypes = Object.assign({}, pitchTypes);
        for (let i = 0; i < 6; i++) {
            curPitchTypes[event.currentTarget[i].id] = event.currentTarget[i].checked;
        }
        setPitchTypes(curPitchTypes);
    }

    function addRequiredCols(cols, extraCols) {
        let finalColumns = [...cols];
        extraCols = extraCols ?? colData.extraColumns;
        switch (formOptions.tabName) {
            case 'players':
                if (!~finalColumns.findIndex(col => col.id === 10000)) {
                    finalColumns.unshift(...extraCols.find(ec => ec.name === 'Rank').columns);
                }
                break;
            case 'teams':
                finalColumns = finalColumns.filter(col => !['Player', 'Age'].includes(col.title));
                break;
            case 'mlb':
                finalColumns = finalColumns.filter(col => !['Player', 'Tm', 'Age'].includes(col.title));
                if (!~finalColumns.findIndex(col => col.id === 499)) {
                    finalColumns.unshift(...extraCols.find(ec => ec.name === 'League').columns);
                }
                break;
            default:
                break;
        }
        if (groupId === 21) {
            // For pitching arsenal we only want to display columns based on the pitches selected
            finalColumns = finalColumns.slice(0, finalColumns.findIndex(col => col.id === 47) + 1);
            Object.entries(pitchTypes)
                .forEach(([key, val]) => {
                    if (val) {
                        extraCols.find(ec => ec.name === key).columns
                            .forEach(extraColumn => {
                                if (!~finalColumns.findIndex(finalCol => finalCol.id === extraColumn.id)) {
                                    finalColumns.push(extraColumn);
                                }
                            })
                    }
                })
        }
        return finalColumns;
    }

    function refreshData(cb) {
        setData([]);
        if (cb) {
            cb();
        }
    }

    function getData() {
        setLoading(true);

        let start = Date.now();
        setData([]);
        console.log(`Starting call to get data at: ${Date.now() - start}`);
        GetLeaderboardsData(formOptions, colData.selectedCols)
            .then((value) => {
                console.log(`finished getting data at: ${Date.now() - start}`);
                setData(value);
                setLoading(false);
                console.log(`finished setting data at: ${Date.now() - start}`);
            })
    }

    return (
        <div className='body leaderboards'>
            <LeaderBoardForm
                setFormOptions={setFormOptions}
                setGroupId={setGroupId}
                qualifierThresholds={qualifierThresholds}
            />
            <Button style={{ margin: 'auto 0px auto auto' }} variant='success'
                onClick={() => getData()}
            >
                Submit
            </Button>
            <br />
            <br />

            {
                data && data.length > 0 &&
                <React.Fragment>
                    <ExcelExporter reportName={'Leaderboards'} data={data} columns={colData.selectedCols} />
                    <br />
                    <br />
                </React.Fragment>
            }

            {
                formOptions.category === 'batting' &&
                <Navbar bg='light' variant='light' className='leaderboardsGroupNav'>
                    <Nav>
                        <Nav.Link className={groupId === 10 ? 'selectedTab' : null} onClick={() => setGroupId(10)}>Overview</Nav.Link>
                        <Nav.Link className={groupId === 11 ? 'selectedTab' : null} onClick={() => setGroupId(11)}>Standard</Nav.Link>
                        <Nav.Link className={groupId === 12 ? 'selectedTab' : null} onClick={() => setGroupId(12)}>Advanced</Nav.Link>
                        <Nav.Link className={groupId === 13 ? 'selectedTab' : null} onClick={() => setGroupId(13)}>Statcast</Nav.Link>
                        <Nav.Link className={groupId === 22 ? 'selectedTab' : null} onClick={() => setGroupId(22)}>Contracts</Nav.Link>
                    </Nav>
                </Navbar>
            }
            {
                formOptions.category === 'pitching' &&
                <Navbar bg='light' variant='light' className='leaderboardsGroupNav'>
                    <Nav>
                        <Nav.Link className={groupId === 15 ? 'selectedTab' : null} onClick={() => setGroupId(15)}>Overview</Nav.Link>
                        <Nav.Link className={groupId === 16 ? 'selectedTab' : null} onClick={() => setGroupId(16)}>Standard</Nav.Link>
                        <Nav.Link className={groupId === 17 ? 'selectedTab' : null} onClick={() => setGroupId(17)}>Run Prevention</Nav.Link>
                        <Nav.Link className={groupId === 18 ? 'selectedTab' : null} onClick={() => setGroupId(18)}>Usage</Nav.Link>
                        <Nav.Link className={groupId === 19 ? 'selectedTab' : null} onClick={() => setGroupId(19)}>Batted Ball</Nav.Link>
                        <Nav.Link className={groupId === 20 ? 'selectedTab' : null} onClick={() => setGroupId(20)}>Statcast</Nav.Link>
                        <Nav.Link className={groupId === 21 ? 'selectedTab' : null} onClick={() => setGroupId(21)}>Arsenal</Nav.Link>
                        <Nav.Link className={groupId === 22 ? 'selectedTab' : null} onClick={() => setGroupId(22)}>Contracts</Nav.Link>
                    </Nav>
                </Navbar>
            }
            {
                formOptions.category === 'pitching' && groupId === 21 &&
                <React.Fragment>
                    <Row className='justify-content-md-center sub-tab-form'>
                        <Col md='7'>
                            <Form onChange={(event) => pitchArsenalChange(event)}>
                                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Pitch Types: </Form.Label>
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='fourseam'
                                    label='Four-seam(FF)'
                                />
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='sinker'
                                    label='Sinker(SI)'
                                />
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='slider'
                                    label='Slider(SL)'
                                />
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='cutter'
                                    label='Cutter(FC)'
                                />
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='curve'
                                    label='Curve(CU)'
                                />
                                <Form.Check
                                    inline
                                    type='checkbox'
                                    id='change'
                                    label='Change(CH)'
                                />
                                <Button variant='secondary' type='button' style={{ verticalAlign: 'top', padding: '0px', }}
                                    onClick={() => {
                                        const tempColData = Object.create(colData);
                                        tempColData.selectedCols = addRequiredCols(colData.selectedCols);
                                        setColData(tempColData);
                                    }}
                                >Select Pitches</Button>
                            </Form>
                        </Col>
                    </Row>
                </React.Fragment>
            }
            {
                formOptions.category === 'fielding' &&
                <Navbar bg='light' variant='light' className='leaderboardsGroupNav'>
                    <Nav>
                        <Nav.Link className={groupId === 14 ? 'selectedTab' : null} onClick={() => setGroupId(14)}>Overview</Nav.Link>
                        <Nav.Link className={groupId === 37 ? 'selectedTab' : null} onClick={() => setGroupId(37)}>Catcher</Nav.Link>
                    </Nav>
                </Navbar>
            }
            <br />

            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
            <div style={{ width: data ? (colData.selectedCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }}>
                {
                    data && data.length > 0 &&
                    <TabulatorTable
                        cols={colData.selectedCols}
                        data={data}
                        paginationSize={25}
                        initSort={initSort}
                        dataTreeElementColumn='Team'
                    />
                }
            </div>
            {
                /*
                <div className='pdfSection' style={{ width: data ? (selectedCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }}>
                    {
                        mode === 'date' &&
                        <p className='dateRange'>
                            {startDate.getMonth() + 1}/{startDate.getDate()}/{startDate.getFullYear()}
                            -
                            {endDate.getMonth() + 1}/{endDate.getDate()}/{endDate.getFullYear()}
                        </p>
                    }
                    {
                        data && data.length > 0 &&
                        <TabulatorTable cols={selectedCols} data={data} initSort={initSort} tab={'Leaderboards'} />
                    }
                </div>
                */
            }


            <br />
            <ColumnSelector
                columns={colData.columns}
                selectedColumns={colData.selectedCols}
                groups={colData.groups}
                setColumns={(columns) => {
                    const tempColData = Object.create(colData);
                    tempColData.selectedCols = addRequiredCols(columns);
                    setColData(tempColData);
                }}
            />
            <br />
        </div>
    )
}

export default Leaderboards;